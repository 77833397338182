import { getCorporatePageSlugsWithSiteId } from 'utils/hostnames';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import {
  createCorporatePageQuery,
  getCorporatePage,
  GetCorporatePageQueryResult,
  imageUrlFor,
  PageParent,
  SiteIdTypeWithoutProjects,
} from '@klokgroep/sanity';
import { CorporatePage } from '@klokgroep/shared-components/src/CorporatePage';
import { getRevalidateInterval } from '@klokgroep/shared-components/utils/getRevalidateInterval';
import {
  getPropertiesWithPreviewAndSitewideData,
  PropertiesWithPreviewNavigationAndSocialsAndMetaData,
} from '@klokgroep/shared-components/utils/getPropertiesWithPreviewNavigationAndSocials';
import { getNextJsRedirectObjectForSiteIdAndSlug } from '@klokgroep/shared-components/utils/getNextJsRedirectObjectForSiteIdAndSlug';

type Properties = PropertiesWithPreviewNavigationAndSocialsAndMetaData<GetCorporatePageQueryResult>;

const Page: NextPage<Properties> = ({ ...rest }) => <CorporatePage {...rest} />;

export const getStaticPaths: GetStaticPaths = async () => {
  const data = await getCorporatePageSlugsWithSiteId();
  const paths = data.map(({ params }) => ({ params: { slugs: params.slugs, siteId: params.siteId } }));
  return { paths, fallback: 'blocking' };
};

const validateParents = (slugs: string[], parent?: PageParent): boolean => {
  if (slugs.length === 0 && !parent) {
    return true;
  }
  const lastSlug = slugs.pop();
  if (lastSlug !== parent?.slug?.current) {
    return false;
  }
  return validateParents(slugs, parent?.parent);
};

const validateRoute = (slugs: string[], page: Properties) => {
  if (!page?.slug) {
    return false;
  }

  const slugsCopy = [...slugs];
  slugsCopy.pop();

  if (!validateParents(slugsCopy, page.parent as unknown as PageParent)) {
    return false;
  }

  return true;
};

export const getStaticProps: GetStaticProps<
  Properties,
  { slugs: string[]; siteId: SiteIdTypeWithoutProjects },
  { token?: string }
> = async ({ params, preview = false }) => {
  if (!params?.siteId || !params?.slugs) {
    return { notFound: true, revalidate: getRevalidateInterval() };
  }
  const slugs = params.slugs;

  const page = await getCorporatePage(false, slugs, params.siteId);

  const redirect = await getNextJsRedirectObjectForSiteIdAndSlug(params.siteId, slugs.join('/'));

  if (redirect) {
    return redirect;
  }

  if (!validateRoute(slugs, page)) {
    return {
      notFound: true,
      revalidate: getRevalidateInterval(),
    };
  }

  const dataForPreview = {
    query: createCorporatePageQuery(params.siteId, preview),
    params: { siteId: params.siteId, slug: slugs.at(-1) },
  };

  const properties = await getPropertiesWithPreviewAndSitewideData(
    preview,
    dataForPreview,
    page,
    {
      description: page?.seo?.pageSeo?.description || page?.seo?.pageSeo?.description,
      image: page?.seo?.pageSeo?.image ? imageUrlFor(page?.seo?.pageSeo?.image).url() : undefined,
      title: page?.seo?.pageSeo?.title || page.pageHero?.title,
      noIndex: page?.seo?.pageSeo?.noIndex,
      schema: {
        socials: page?.seo?.socials,
        organisation: {
          name: page?.seo?.sitewideSeo?.organisationName,
          legalName: page?.seo?.sitewideSeo?.organisationLegalName,
          description: page?.seo?.sitewideSeo?.organisationDescription,
          founderName: page?.seo?.sitewideSeo?.organisationFounder,
          foundingDate: page?.seo?.sitewideSeo?.organisationFoundingDate,
          numberOfEmployees: page?.seo?.sitewideSeo?.organisationNumberOfEmployees,
          logo: page?.seo?.sitewideSeo?.organisationLogo,
          slogan: page?.seo?.sitewideSeo?.organisationSlogan,
        },
      },
    },
    params.siteId
  );

  return {
    props: properties,
    revalidate: getRevalidateInterval(),
  };
};

export default Page;
