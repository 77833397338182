import { GetCorporatePageQueryResult, createHrefForTypeAndSlug } from '@klokgroep/sanity';
import { PageHero } from '@klokgroep/shared-components/src/PageHero';
import React, { useMemo } from 'react';
import { CorporateContentModules } from '@klokgroep/shared-components/src/CorporateContentModules';
import { useCommonTranslations } from '@klokgroep/shared-components/utils/useTranslations';

export const CorporatePage = ({
  pageHero,
  corporateContentModules = [],
  parent,
  ...rest
}: GetCorporatePageQueryResult) => {
  const t = useCommonTranslations();

  const backLink = useMemo(
    () =>
      parent
        ? { href: createHrefForTypeAndSlug(parent), label: t('back_to', { title: parent?.title?.replaceAll('*', '') }) }
        : undefined,
    [parent, t]
  );

  return (
    <section>
      <PageHero
        image={pageHero?.image}
        title={pageHero?.title}
        richText={pageHero?.richText}
        mediaItems={pageHero?.mediaItems}
        highlightedContentBlock={pageHero?.highlightedContentBlock}
        button={pageHero?.button}
        backLink={backLink}
        {...rest}
      />
      <CorporateContentModules modules={corporateContentModules} />
    </section>
  );
};
